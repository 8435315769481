
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import TableBasic from "@/components/tables/TableBasic.vue";
import ApiService from "@/core/services/ApiService";
import MeasuresTypeForm from "@/views/catalog/measures_type/MeasuresTypeForm.vue";

export default defineComponent({
  name: "MeasuresType",
  components: {
    TableBasic,
    MeasuresTypeForm,
  },

  setup() {
    const { t } = useI18n();
    const header = ref([{ column: t("iname"), key: "name" }]);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([]);
    const dataObject = ref({});
    const dialogVisible = ref(false);
    const itemID = ref("");
    const modalMode = ref("add");

    const createElement = () => {
      dialogVisible.value = true;
      itemID.value = "";
      modalMode.value = "add";
    };

    const getItems = () => {
      ApiService.get("/api/measure-types").then(({ data }) => {
        isLoading.value = false;
        const items: Record<string, any> = [];
        dataList.value = data;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        data.forEach((element) => {
          const td = {
            id: element.id,
            name: element.name,
            code: element.code,
            remark: element.remark,
            buttons: [{ type: "EDIT", id: element.id }],
          };
          items.push(td);
        });
        dataObject.value = items;
      });
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getItems();
    };

    onMounted(() => {
      getItems();
    });

    const actionsButton = (param1, param2) => {
      if (param2 == "EDIT") {
        dialogVisible.value = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    return {
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
    };
  },
});
